import React from 'react'

import Layout from '../components/layout'

const AboutPage = () => (
  <Layout>
    <h1 className="mt-5">Impressum</h1>

    <h5>Präsidierender Bund:</h5>
    <p>Turnerschaft Armino-Hercynia im MK<br />
      Wilhelmshavener Straße 10<br />
      30167 Hannover<br />
      Tel.: 0511 - 131 89 18<br />
      E-Mail: <a href="info@armino-hercynia.de">info@armino-hercynia.de</a><br />
      Homepage: <a href="armino-hercynia.de">armino-hercynia.de</a>
    </p>

    <h2 className="mt-5">Redaktion des Marburger Konventes</h2>
    <h5>MK-Redakteur</h5>
    <p>Michael Strumilo (Turnerschaft Hansea Hannover im MK)</p>
    <h5>Homepage</h5>
    <p>E-Mail: <a href="mailto:info@marburger-konvent.de">info@marburger-konvent.de</a></p>
  </Layout>
)

export default AboutPage 